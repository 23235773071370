import styled from 'styled-components';
import IconArrowGreenRight from '../../images/icon-arrow-yellow-right.png';

export const Body = styled.div`
  padding: 40px 110px 0px;
  padding-bottom: 0px;
  @media (max-width: 1399px) {
    padding: 20px 50px 20px 50px;
  }

  @media (max-width: 767px) {
    padding: 35px 35px 0 35px;
  }
  overflow: hidden;
`;
export const ImageStyleBlockRight = styled.img`
  width: 25%;
  height: auto;
  float: right;
  text-align: center;
  margin: 0 0 40px 60px;
  @media (max-width: 1399px) {
    display: block;
    float: none;
    margin: 0 0 40px 0px;
  }
  @media (max-width: 991px) {
    display: block;
    float: none;
    width: 40%;
    margin: 0 0 40px 0px;
  }
  @media (max-width: 767px) {
    display: block;
    float: none;
    margin: 0 0 25px 0;
    width: 100%;
  }
`;
export const LinkStyle = styled.a`
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
`;
export const Header3 = styled.h2`
  margin: 0 0 35px 0;
  font-family: 'Figtree', sans-serif;
  font-size: 28px;
  line-height: 35px;
  color: #55aa4c;
  @media (max-width: 1399px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const Header4 = styled.h4`
  overflow: hidden;
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1439px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const ParaText = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  :last-child {
    margin: 0px;
  }
  color: rgba(0, 0, 0, 0.8);
  i {
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 991px) {
      font-size: 15px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
  }
  code {
    font-family: 'HelveticaNeue-Light', arial, sans-serif;
    font-size: 20px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.6);
  }
  a > code {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 17px 35px 13px 35px;
    margin: 0 5px 5px 0;
    font-family: 'Adelle-Bold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #55aa4c;
    border: 2px solid #55aa4c;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
    border-radius: 100px;
    cursor: pointer;
    /* code {
      font: inherit;
    } */
    /* @media (max-width: 1399px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    @media (max-width: 1199px) {
      font-size: 25px;
      line-height: 25px;
    } */

    @media (max-width: 767px) {
      padding: 10px 20px 6px 20px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
    }
    :hover {
      top: -5px;
      color: #55aa4c;
      border-color: #55aa4c;
      box-shadow: 0 5px 0 0 #55aa4c;
    }
  }
  @media (max-width: 1399px) {
    font-size: 16px;
    margin: 0 0 30px 0;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 24px;
  }
`;
export const ListContainer = styled.ul`
  padding: 0;
  margin: 0 0 30px 0;
`;
export const ListText = styled.li`
  padding: 0 0 15px 30px;
  background: url(${IconArrowGreenRight}) no-repeat 0 9px;
  background-size: 10px auto;
  list-style: none;
  margin: 0;
  ${ParaText} {
    margin: 0;
  }
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;
export const Header2 = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 40px;
  line-height: 45px;
  color: #0e4c80;
  @media (max-width: 1399px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 26px;
  }
`;
export const ItalicParaText = styled(ParaText)`
  font-style: italic;
`;
export const AnchorButton = styled.a`
  padding: 16px 85px 14px 15px;
  margin: 0 5px 30px 0;
  text-decoration: none;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase !important;
  text-align: left;
  color: #037ec4;
  background: #ebf5fa url(${IconArrowGreenRight}) no-repeat 95% center;
  background-size: 12px auto;
  &:hover {
    background: #ebf5fa url(${IconArrowGreenRight}) no-repeat 91% center;
    background-size: 12px auto;
    transition: background-position 0.15s ease-out;
    border: none;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;
export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 40px 0 40px 0;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #969696;
`;
export const Header = styled.h1`
  font-family: 'MrAlex', sans-serif;
  font-weight: normal !important;
  color: #00539d;
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 10px;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const SpanStyle = styled.span`
  position: relative;
  top: -200px;
  @media (max-width: 991px) {
    top: -50px;
  }
`;
export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: rgba(0, 0, 0, 0.8);
  margin: 40px 0;
  clear: both;
  @media (max-width: 1399px) {
    margin: 35px 0;
  }
  @media (max-width: 991px) {
    margin: 20px 0;
  }
`;
